import {simpleHash} from "./hash";

const userTailwindColors = [
    
    // We include complete tailwind class names here instead of just the plain colors. 
    // https://tailwindcss.com/docs/content-configuration#dynamic-class-names
    
    // darker tones
    'outline-red-800',
    'outline-orange-800',
    'outline-amber-800',
    'outline-yellow-800',
    'outline-lime-800',
    'outline-green-800',
    'outline-emerald-800',
    'outline-teal-800',
    'outline-cyan-800',
    'outline-sky-800',
    'outline-blue-800',
    'outline-indigo-800',
    'outline-violet-800',
    'outline-purple-800',
    'outline-fuchsia-800',
    
    // lighter tones
    'outline-red-600',
    'outline-orange-600',
    'outline-amber-600',
    'outline-yellow-600',
    'outline-lime-600',
    'outline-green-600',
    'outline-emerald-600',
    'outline-teal-600',
    'outline-cyan-600',
    'outline-sky-600',
    'outline-blue-600',
    'outline-indigo-600',
    'outline-violet-600',
    'outline-purple-600',
    'outline-fuchsia-600',
];

export function getUserTailwindColor(email: string): string {
    const index = simpleHash(email, userTailwindColors.length - 1);
    return userTailwindColors[index];
}