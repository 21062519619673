import {getRandomMalaphors, MalaphorDTO} from "../api/api";
import {MalaphorEntry} from "../components/Malaphor";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import {randomSeedStr, validateSeed} from "../util/random";

const SEED_PARAM_NAME: string = "seed";

export default function RandomMalaphors() {
    const [searchParams, setSearchParams] = useSearchParams();
    const seed = validateSeed(searchParams.get(SEED_PARAM_NAME));
    const [data, setData] =
        useState<MalaphorDTO[]>([]);

    useEffect(() => {
        document.title = "Satunnaisia"
    }, []);

    useEffect(() => {
        if (seed == null) {
            setSearchParams({
                [SEED_PARAM_NAME]: randomSeedStr()
            }, {
                replace: true
            });
        } else {
            getRandomMalaphors(seed).then(response => {
                setData(response);
            })    
        }
    }, [seed, setSearchParams]);
    
    function refresh() {
        setSearchParams({
            [SEED_PARAM_NAME]: randomSeedStr()
        });
    }

    return (
        <section className="p-8 w-full max-w-screen-md mx-auto">
            {data != null ?
                <div className="text-left flex flex-col justify-between w-full gap-4">
                    {data.map((malaphor) => (
                        <MalaphorEntry malaphor={malaphor} key={malaphor.key} allowForget={true}/>
                    ))}
                </div>
                : ''}

            <div className="mx-auto mt-4">
                <button className="p-2 scale-125 duration-250 ease-in-out
                        transition-transform transform-gpu
                        text-amber-700
                        active:scale-150
                        active:text-amber-950
                        focus:text-amber-900
                        dark:active:text-amber-400
                        dark:focus:text-amber-500
                        dark:focus:drop-shadow-[0_0_5px_rgba(227,108,35,0.5)]" onClick={refresh}>
                    <RefreshIcon></RefreshIcon>
                </button>
            </div>
        </section>
    );
}
