
const MAX_INT = 2147483647;

export function validateSeed(seed: string | null): number | null {
    const number = parseInt(seed || '', 10);
    if (isNaN(number)) return null;
    if (number < 0 || number > MAX_INT) return null;
    return number;
}

export function randomSeed() {
    return Math.floor(Math.random() * MAX_INT);
}

export function randomSeedStr() {
    return randomSeed().toString(10);
}