import React, {createContext, useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {GoogleOAuthProvider} from '@react-oauth/google';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Navigate, RouterProvider,} from "react-router-dom";
import RandomMalaphors from "./routes/randomMalaphors";
import ErrorPage from "./error-page";
import RootLayout from "./routes/layout";
import MostLikedMalaphors, {
    FILTER_PARAM_NAME,
    SCROLL_FETCH_SIZE,
    SORT_PARAM_NAME,
    UPTO_PARAM_NAME
} from "./routes/mostLiked";
import ViewMalaphor from "./routes/view";
import SearchMalaphors from "./routes/search";
import {getMostLiked, LoginStatus, MostLikedSort, validateLogin} from "./api/api";
import LoginPage from "./routes/login";
import {clearLoginStatus, loadLoginStatus} from "./util/client-storage";

export interface LoginStatusProperty {
    loginStatus: LoginStatus,
    setLoginStatus: (value: LoginStatus) => void
}

export const LoginStatusContext= createContext<LoginStatusProperty>({
    loginStatus: {},
    setLoginStatus: () => null
});

function App({children}: {children: any}) {
    const [loginStatus, setLoginStatus] = useState(loadLoginStatus())

    const value = {loginStatus, setLoginStatus}

    useEffect(() => {
        if (loginStatus?.authToken!!) {
            validateLogin().then(valid => {
                if (!valid) {
                    clearLoginStatus();
                    setLoginStatus({});    
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <LoginStatusContext.Provider value={value}>
            {children}
        </LoginStatusContext.Provider>
    )
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Navigate to="satunnaisia" replace />
            },
            {
                path: "satunnaisia",
                element: <RandomMalaphors />,
            },
            {
                path: "suosituimmat",
                element: <MostLikedMalaphors />,
                loader: async ({request}) => {
                    const searchParams = new URL(request.url).searchParams;
                    const filter = searchParams.get(FILTER_PARAM_NAME) || '';
                    const sort = searchParams.get(SORT_PARAM_NAME) as MostLikedSort || MostLikedSort.CREATED;
                    const upto = parseInt(searchParams.get(UPTO_PARAM_NAME) || '', 10) || SCROLL_FETCH_SIZE;
                    return [await getMostLiked(filter, sort, upto), filter, sort, upto];
                }
            },
            {
                path: "etsi",
                element: <SearchMalaphors />
            },
            {
                path: "/katso/:key",
                element: <ViewMalaphor />,
            },
            {
                path: "/k/:key",
                element: <ViewMalaphor />,
            },
            {
                path: "google-login",
                element: <LoginPage />,
            }
        ]
    },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <GoogleOAuthProvider clientId="612411918177-vma6a0tf3kp9fc9hmmm5gkl11eom44t9.apps.googleusercontent.com">
        <React.StrictMode>
            <App>
                <RouterProvider router={router} />    
            </App>
        </React.StrictMode>
    </GoogleOAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
