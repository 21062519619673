export function formatTimeSince(isoDateTime?: string): string | null {
    if (isoDateTime == null) return null;

    const dateTime = new Date(isoDateTime);
    const now = new Date();
    const secondsAgo = Math.floor((now.getTime() - dateTime.getTime()) / 1000);

    const intervals = {
        year: Math.floor(secondsAgo / (60 * 60 * 24 * 365)),
        month: Math.floor(secondsAgo / (60 * 60 * 24 * 30)),
        week: Math.floor(secondsAgo / (60 * 60 * 24 * 7)),
        day: Math.floor(secondsAgo / (60 * 60 * 24)),
        hour: Math.floor(secondsAgo / (60 * 60)),
        minute: Math.floor(secondsAgo / 60)
    };
        
    if (intervals.year > 0) {
        return `${intervals.year} vuo${intervals.year > 1 ? 'tta' : 'si'} sitten`;
    } else if (intervals.month > 0) {
        return `${intervals.month} kuukau${intervals.month > 1 ? 'tta' : 'si'} sitten`;
    } else if (intervals.week > 0) {
        return `${intervals.week} viikko${intervals.week > 1 ? 'a' : ''} sitten`;
    } else if (intervals.day > 0) {
        return `${intervals.day} päivä${intervals.day > 1 ? 'ä' : ''} sitten`;
    } else if (intervals.hour > 0) {
        return `${intervals.hour} tunti${intervals.hour > 1 ? 'a' : ''} sitten`;
    } else if (intervals.minute > 0) {
        return `${intervals.minute} minuutti${intervals.minute > 1 ? 'a' : ''} sitten`;
    } else {
        return 'juuri nyt';
    }
}
