import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError() as any;
    console.error(error);

    return (
        <div id="error-page" className="m-16 text-center space-y-5">
            <h1>Hups!</h1>
            <p>Eihän tuommoista olekaan...</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}