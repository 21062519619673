/**
 * Takes a string a converts it into a number between 0 and max. Max should be between 1 and 100.
 * 
 * Note: this is just something I made up, it's probably not very good.
 */
export function simpleHash(input: string, max: number): number {
    if (max < 1 || max > 100)
        throw new Error('max must be an integer between 1 and 100')

    // Convert to 32bit integer
    max |= 0;
    
    let hash = 0;
    if (input.length === 0) return hash;

    let sum = 0;
    for (let i = 0; i < input.length; i++) {
        sum += input.charCodeAt(i);
    }

    hash = Math.imul(sum % 500, 1223);
    hash |= 0;

    return hash % (max + 1);
}
