import {loadLoginStatus} from "./client-storage";

export function authHeader(): string | undefined {
    const authToken = loadLoginStatus().authToken
    return authToken ? `Bearer ${authToken}` : undefined;
}

export function createAuthHeader(): [string, string][] {
    const result: [string, string][] = [];
    addAuthHeader(result);
    return result;
}

export function addAuthHeader(headers: [string, string][]) {
    const value = authHeader()
    
    if (value!!)
        headers.push(["Authorization", value]);
}
