import React, {useEffect, useState} from 'react';
import {MalaphorDetailsDTO, viewMalaphor} from "../api/api";
import {useParams} from "react-router-dom";
import MergeIcon from '@mui/icons-material/Merge';

export default function ViewMalaphor() {
    const [malaphor, setMalaphor] = useState<MalaphorDetailsDTO | null>(null);
    const {key} = useParams();

    // TODO should use a loader instead?
    useEffect(() => {
        if (!key) return;
        viewMalaphor(key).then(response => {
            setMalaphor(response);
            document.title = response.text;
        });
    }, [key]);

    return (
        <section className="m-8 md:w-3/4 lg:w-1/2 md:mx-auto">
            {malaphor ? (
                <div className="flex flex-col items-center gap-8">
                    <div className="text-3xl text-center">{malaphor.text}</div>
                    <div className="scale-150 opacity-50">
                        <MergeIcon></MergeIcon>
                    </div>
                    <div className="flex gap-4">
                        <div className="text-xl opacity-50 text-center w-1/2">{malaphor.parent1}</div>
                        <div className="text-xl opacity-50 text-center w-1/2">{malaphor.parent2}</div>
                    </div>
                </div>
            ) : ''}
        </section>
    )
}