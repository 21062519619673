import {CredentialResponse} from "@react-oauth/google";
import {addAuthHeader, createAuthHeader} from "../util/http";

export interface LikeDTO {
    key: string;
    like: boolean;
    allowForget: boolean;
}

export interface MalaphorDTO {
    key: string;
    text: string;
    likes: number;
    saved: boolean;
    liked: boolean;
    created: string;
    updated: string;
}

export interface HilitePart {
    text: string;
    isHighlighted: boolean;
}

export interface MalaphorSearchResult {
    value: MalaphorDTO,
    hiliteParts: HilitePart[]
}

export interface MalaphorDetailsDTO {
    key: string;
    text: string;
    parent1: string;
    parent2: string;
}

export interface LoginStatus {
    authToken?: string;
    name?: string;
    initials?: string;
    email?: string;
    error?: string;
}

export enum MostLikedSort {
    LIKES = "eniten",
    CREATED = "uusin",
    ALPHABETIC = "aakkos"
}

interface MostLikedDTO {
    filter: string;
    sort: string;
    upto: number;
}

function getEnumName(value: MostLikedSort): string {
    switch (value) {
        case MostLikedSort.LIKES: return "LIKES";
        case MostLikedSort.CREATED: return "CREATED";
        case MostLikedSort.ALPHABETIC: return "ALPHABETIC";
    }
}

export interface MostLikedResults {
    rows: MalaphorDTO[];
    total: number;
}

export async function getMostLiked(filter: string, sort: MostLikedSort, upto: number): Promise<MostLikedResults> {
    const dto: MostLikedDTO = {
        filter,
        sort: getEnumName(sort),
        upto
    }
    const headers: [string, string][] = [["Content-Type", "application/json"]];
    addAuthHeader(headers);
    
    const response = await fetch('/api/saved-malaphors', {
        method: 'POST', 
        headers, 
        body: JSON.stringify(dto)}
    );
    
    return await response.json();
}

export async function getRandomMalaphors(seed: number): Promise<MalaphorDTO[]> {
    const response = await fetch(`/api/random-malaphors?seed=${seed}`, {method: 'GET', headers: createAuthHeader()});
    return await response.json();
}

export async function searchMalaphor(query: string, seed: number): Promise<MalaphorSearchResult> {
    const response = await fetch(`/api/search-single?seed=${seed}`, {method: 'POST', body: query, headers: createAuthHeader()});
    return await response.json();
}

export async function likeMalaphor(dto: LikeDTO): Promise<MalaphorDTO> {
    const headers: [string, string][] = [["Content-Type", "application/json"]];
    addAuthHeader(headers);
    const response = await fetch('/api/like-malaphor', {
        method: 'POST',
        body: JSON.stringify(dto),
        headers
    });
    return await response.json();
}

export async function viewMalaphor(key: string): Promise<MalaphorDetailsDTO> {
    const response = await fetch(`/api/view-malaphor/${key}`);
    return await response.json().then(response => response.value);
}

export async function login(jwt: CredentialResponse): Promise<LoginStatus> {
    const response = await fetch('/api/login', {
        method: 'POST',
        body: JSON.stringify(jwt),
        headers: {
            "Content-Type": "application/json",
        },
    });
    return await response.json();
}

export async function validateLogin(): Promise<boolean> {
    const response = await fetch('/api/validate-login', {
        method: 'POST',
        headers: createAuthHeader(),
    });
    return await response.json();
}