import {CredentialResponse, GoogleLogin} from '@react-oauth/google';
import * as jose from 'jose';
import {useContext, useEffect} from "react";
import LoginButton from "../components/loginButton";
import {LoginStatusContext} from "../index";
import {clearLoginStatus, storeLoginStatus} from "../util/client-storage";
import {login} from "../api/api";

const DEBUG = false;

export default function LoginPage() {
    
    const {loginStatus, setLoginStatus} = useContext(LoginStatusContext);
    
    useEffect(() => {
        document.title = "Kirjautuminen"
    }, []);
    
    function handleResponse(response: CredentialResponse) {
        if (DEBUG) {
            showDebugInfo(response);
        }

        login(response).then((response) => {
            storeLoginStatus(response);
            setLoginStatus(response);
        });
    }
    
    function handleError() {
        console.log("google login failed");
    }
    
    function logout() {
        clearLoginStatus();
        setLoginStatus({});
    }
    
    return (
        <div className="w-full max-w-screen-sm mx-auto flex flex-col items-center">
            <h1 className="m-8 text-xl">
                {loginStatus.name == null ?
                    <span>Sisäänkirjautuminen</span>
                    : <span>Olet sisäänkirjautunut tunnuksella:</span>
                }
            </h1>

            <div className="w-fit">
                {loginStatus.name == null ?
                    <GoogleLogin width="250" onSuccess={handleResponse} onError={handleError}/>
            :
                    <div className="flex flex-col">
                        <div className="flex items-center space-x-2">
                            <LoginButton initials={loginStatus.initials} email={loginStatus.email}/>
                            <div className="text-2xl">{loginStatus.email || loginStatus.name}</div>
                        </div>

                        <button onClick={logout} className="text-orange-300">Kirjaudu ulos</button>
                    </div>
                }

                {loginStatus.error != null ?
                    <div className="text-red-400 my-4">{loginStatus.error}</div>
                    : ''
                }
            </div>

            <div className="m-8 space-y-4">
                <p>Sisäänkirjautuneena voit:</p>
                <ul className="ml-4 list-disc">
                    <li>Tykätä viisauksista kuten kunnon ihmiset ja kasvattaa tykkäyslaskuria</li>
                    <li>Listata henkilökohtaiset tykkäyksesi (tulossa)</li>
                    <li>Poistaa huonoja viisauksia (tulossa)</li>
                </ul>
                <p>Tuemme ainoastaan Kuukkelikirjautumista.</p>
            </div>
        </div>
    )
}


function showDebugInfo(response: CredentialResponse) {
    const cre = response.credential;
    console.log(cre);
    if (cre) {
        const header = jose.decodeProtectedHeader(cre);
        console.log(header);

        const responsePayload = jose.decodeJwt(cre);
        console.log("full payload:", responsePayload);
        console.log("ID: " + responsePayload.sub);
        console.log('Name: ' + responsePayload.name);
        console.log("Email: " + responsePayload.email);
    }
}
