import {getUserTailwindColor} from "../util/color";

export default function LoginButton(args: {initials?: string, email?: string}) {
    const outlineColor = getUserTailwindColor(args.email || args.initials || '');
    const outlineCircle = `outline ${outlineColor} rounded-full w-7 h-7 flex justify-center`;
    
    return (
        <div className={outlineCircle}>
            <div className="self-center text-sm">{args.initials}</div>
        </div>
    );
}