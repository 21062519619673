import {useContext, useEffect, useState} from "react";
import {HilitePart, likeMalaphor, MalaphorDTO} from "../api/api";
import {Link} from "react-router-dom";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {formatTimeSince} from "../util/time";
import {LoginStatusContext} from "../index";
import {hiliteSubstring} from "../util/hilite";

interface Args {
    malaphor: MalaphorDTO;
    highlight?: string | HilitePart[];
    allowForget?: boolean;
}

export function MalaphorEntry({malaphor, highlight, allowForget}: Args) {
    const forgetEnabled = allowForget || false;
    const loggedIn = useContext(LoginStatusContext).loginStatus.authToken != null;
    
    const interactive = loggedIn || allowForget;
    
    const [liked, setLiked] = useState<boolean>(isLiked(loggedIn, malaphor.saved, malaphor.liked));
    const [likes, setLikes] = useState<number>(malaphor.likes);

    const hiliteParts = typeof highlight === 'string' ? hiliteSubstring(malaphor.text, highlight) : highlight
    const hilitePartsSafe = hiliteParts || [{text: malaphor.text, isHighlighted: false}];
    
    function like(value: boolean) {
        likeMalaphor({key: malaphor.key, like: value, allowForget: forgetEnabled}).then(response => {
            setLiked(isLiked(loggedIn, response.saved, response.liked));
            setLikes(response.likes);
        });
    }
    
    useEffect(() => {
        setLiked(isLiked(loggedIn, malaphor.saved, malaphor.liked));
        setLikes(malaphor.likes);
    }, [loggedIn, malaphor.key, malaphor.likes, malaphor.saved, malaphor.liked]);
    
    return (
        <div className="text-2xl flex items-start justify-between gap-4 md:px-4 md:py-2 min-h-24">
            <div>
                <MalaLink malaphorKey={malaphor.key} parts={hilitePartsSafe}/>
                <div className="text-base text-black dark:text-white/70">{formatTimeSince(malaphor.created)}</div>
            </div>

            <div className="flex items-center flex-col mt-0.5">
                {interactive ?
                    <button onClick={() => like(!liked)} type="button" title="Tykkää"
                            className="text-lg cursor-pointer
                                       duration-150 ease-in-out
                                       transition-transform transform-gpu 
                                       active:-translate-y-1
                                       active:scale-125
                                       text-amber-700
                                       active:text-amber-400
                                       focus:text-amber-500">
                        <LikeIcon isLiked={liked}/>
                    </button> :
                    <div className="text-lg text-amber-700">
                        <LikeIcon isLiked={liked}/>
                    </div>
                }
                <div className={"text-base font-serif " + (likes === 0 ? 'invisible' : '')}
                     title="Tykkäyksiä">{likes}</div>
            </div>
        </div>
    )
}

function LikeIcon(args : {isLiked: boolean}) {
    return (
        <>{args.isLiked ? <FavoriteIcon/> : <FavoriteBorderIcon/>}</>
    );
}

function MalaLink(args: { malaphorKey: String, parts: HilitePart[] }) {

    const miniKey = args.malaphorKey.substring(0, 5) + "-" + args.malaphorKey.substring(13, 18)

    const className =
        "text-orange-700 visited:text-orange-900 " +
        "dark:text-orange-300 dark:visited:text-orange-500";

    return (
        <Link to={'/k/' + miniKey} className={className}>
            {args.parts.map((p, index) => (
                <span className={p.isHighlighted ? "underline underline-offset-8" : ""} key={index}>{p.text}</span>
            ))}
        </Link>
    );
}

function isLiked(loggedIn: boolean, saved: boolean, liked: boolean) {
    return loggedIn ? liked : saved;
}
    