import {LoginStatus} from "../api/api";

const LS_LOGIN_KEY = "kansanviisaus-login";

export const loadLoginStatus: () => LoginStatus = () => {
    try {
        const serialized = localStorage.getItem(LS_LOGIN_KEY);
        if (serialized) {
            const parse = JSON.parse(serialized);
            return {
                name: parse.name,
                email: parse.email,
                authToken: parse.authToken,
                initials: parse.initials
            }
            // TODO validate stored item
        }
    } catch (e: any) {
        clearLoginStatus();
    } finally {}

    return {};
}

export const storeLoginStatus = (status: LoginStatus) => {
    try {
        localStorage.setItem(LS_LOGIN_KEY, JSON.stringify(status))
    } finally {}
}

export const clearLoginStatus = () => {
    try {
        localStorage.removeItem(LS_LOGIN_KEY);    
    } finally {}
}
