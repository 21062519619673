import React, {useEffect, useState} from 'react';
import {MalaphorSearchResult, searchMalaphor} from "../api/api";
import {MalaphorEntry} from "../components/Malaphor";
import {Form, useSearchParams} from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import {randomSeedStr, validateSeed} from "../util/random";

const Q_PARAM_NAME: string = "q";
const SEED_PARAM_NAME: string = "seed";

export default function SearchMalaphors() {
    const [result, setResult] = useState<MalaphorSearchResult | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const urlQuery = searchParams.get(Q_PARAM_NAME) || '';
    const seed = validateSeed(searchParams.get(SEED_PARAM_NAME));
    const [inputValue, setInputValue] = useState(urlQuery);

    useEffect(() => {
        setInputValue(urlQuery);

        document.title = urlQuery === '' ? `Etsi` : `Etsi: "${urlQuery}"`;

        if (urlQuery && seed) {
            searchMalaphor(urlQuery, seed).then(result => {
                setResult(result);
            });
        } else {
            setResult(null);
        }
    }, [urlQuery, seed]);

    function handleSubmit(e: any) {
        e.preventDefault();
        
        setSearchParams({
            [SEED_PARAM_NAME]: randomSeedStr(),
            [Q_PARAM_NAME]: inputValue
        });
    }
    
    return (
        <section className="p-8 space-y-6 w-full max-w-screen-md mx-auto">
            <Form method="get" className="flex items-center gap-4 w-full" onSubmit={handleSubmit}>
                <input className="px-3 py-2 font-sans placeholder-amber-700
                    w-full bg-white/50 dark:bg-transparent rounded outline outline-1 outline-amber-800 focus:shadow-[0_0_10px_rgba(227,108,35,0.5)]"
                    type="text" name={Q_PARAM_NAME}
                    placeholder="Etsi sananlaskuista" autoFocus autoComplete="off"
                    spellCheck="false"
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                />

                <button type="submit" className="px-2 h-10 rounded 
                        scale-125 duration-250 ease-in-out
                        transition-transform transform-gpu
                        text-amber-700
                        active:scale-150
                        active:text-amber-950
                        focus:text-amber-900
                        dark:active:text-amber-400
                        dark:focus:text-amber-500
                        dark:focus:drop-shadow-[0_0_5px_rgba(227,108,35,0.5)]">
                    <SearchIcon />
                </button>
            </Form>

            <div className="pt-8 w-full">
                {result?.value ?
                    <MalaphorEntry malaphor={result.value} highlight={result.hiliteParts} allowForget={true}/> : ''
                }
            </div>
        </section>
    )
}
