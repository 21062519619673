import {HilitePart} from "../api/api";

export function hiliteSubstring(text: string, highlight?: string): HilitePart[] {
    if (highlight == null) {
        return [{text, isHighlighted: false}];
    } else {
        const normText = text.toLocaleLowerCase("fi");
        const normHiLite = highlight.toLocaleLowerCase("fi");

        const hiliteStart = normText.indexOf(normHiLite);
        if (hiliteStart === -1) {
            return [{text, isHighlighted: false}];
        } else {
            const hiliteEnd = hiliteStart + normHiLite.length;

            const p1 = text.substring(0, hiliteStart);
            const p2 = text.substring(hiliteStart, hiliteEnd);
            const p3 = text.substring(hiliteEnd);

            return [{text: p1, isHighlighted: false},{text: p2, isHighlighted: true},{text: p3, isHighlighted: false}];
        }
    }
}
