import {useEffect} from "react";
import {MostLikedResults, MostLikedSort, MostLikedSort as Sort} from "../api/api";
import {MalaphorEntry} from "../components/Malaphor";
import {useLoaderData, useSearchParams} from "react-router-dom";
import {DebounceInput} from 'react-debounce-input'
import InfiniteScroll from 'react-infinite-scroll-component';
import gothicDot from "../gothic_dot_32.png";

export const SORT_PARAM_NAME: string = "j";
export const FILTER_PARAM_NAME: string = "suodata";
export const UPTO_PARAM_NAME: string = "asti";

export const SCROLL_FETCH_SIZE = 100;

function SortButton({text, onClick, isActive}: {text: string, onClick: () => void, isActive: () => boolean}) {
    return <button 
        className={isActive() ? 'border-b border-orange-500' : ''}
        onClick={onClick}>{text}</button>
}

export default function MostLikedMalaphors() {
    const [results, filter, sort, upto] = 
        useLoaderData() as [MostLikedResults, string, MostLikedSort, number];
    
    const [, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        switch (sort) {
            case Sort.ALPHABETIC:
                document.title = "Suosituimmat - Aakkosjärjestys"
                break;
            case Sort.CREATED:
                document.title = "Suosituimmat - Uusimmat"
                break;
            case Sort.LIKES:
                document.title = "Suosituimmat - Eniten tykkäyksiä"
                break;
        }
    }, [sort]);

    function changeFilter(filter: string) {
        setSearchParams({
            [SORT_PARAM_NAME]: sort,
            [FILTER_PARAM_NAME]: filter
        });
    }
    
    function changeSort(sort: Sort) {
        setSearchParams({
            [FILTER_PARAM_NAME]: filter,
            [SORT_PARAM_NAME]: sort
        });
    }
    
    function fetchData() {
        const nextUpto = upto + SCROLL_FETCH_SIZE;
        setSearchParams({
            [FILTER_PARAM_NAME]: filter,
            [SORT_PARAM_NAME]: sort,
            [UPTO_PARAM_NAME]: nextUpto.toString(10)
        }, {
            replace: true,
            preventScrollReset: true
        });
    }
    
    return (
        <section className="px-8 py-8 w-full max-w-screen-md mx-auto mb-8">
            <div>
                <DebounceInput
                    value={filter}
                    placeholder="Suodata..."
                    debounceTimeout={500}
                    onChange={(e) => changeFilter(e.target.value)}
                    className="px-3 py-2 font-sans placeholder-amber-700
                               w-full bg-white/50 dark:bg-transparent rounded outline outline-1 outline-amber-800 
                               focus:shadow-[0_0_10px_rgba(227,108,35,0.5)]" />
            </div>
            
            <div className="flex space-x-1.5 items-baseline m-4 mb-6">
                <SortButton text={"Aakkosjärjestys"} onClick={() => changeSort(Sort.ALPHABETIC)}
                            isActive={() => sort === Sort.ALPHABETIC}></SortButton>
                <span className="gothic-dot"></span>
                <SortButton text={"Eniten tykätyt"} onClick={() => changeSort(Sort.LIKES)}
                            isActive={() => sort === Sort.LIKES}></SortButton>
                <span className="gothic-dot"></span>
                <SortButton text={"Uusimmat"} onClick={() => changeSort(Sort.CREATED)}
                            isActive={() => sort === Sort.CREATED}></SortButton>
            </div>

            <div className="text-left flex flex-col gap-4 w-full">
                <InfiniteScroll
                    dataLength={results.rows.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={results.rows.length < results.total}
                    loader={<div className="text-center text-neutral-500 text-lg p-4">Ladataan...</div>}
                    endMessage={
                        <div className="text-center text-neutral-500 p-4">
                            &mdash; <img src={gothicDot} alt="end of content" className="w-1.5 inline"/> &mdash;
                        </div>
                    }>
                    {results.rows.map((malaphor) => (
                        <MalaphorEntry malaphor={malaphor} key={malaphor.key} highlight={filter}/>
                    ))}
                </InfiniteScroll>
            </div>
        </section>
    );
}
