import {NavLink, Outlet, ScrollRestoration} from "react-router-dom";
import logo from '../logo_192.png';
import gothicDot from '../gothic_dot_32.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginButton from "../components/loginButton";
import {useContext} from "react";
import {LoginStatusContext} from "../index";

export default function RootLayout() {
    const {loginStatus} = useContext(LoginStatusContext);
    
    return (
        <>
            <div className="bg-amber-50 dark:bg-[#181a1b] border-b border-b-neutral-700/20 dark:border-b-neutral-700">
                <div className="flex items-center justify-between w-full px-4 py-4">
                    <img src={logo} alt="logo" className="h-8 w-8 hidden sm:block"/>
                    <div className="flex items-center space-x-2 text-xl max-w-screen-md">
                        <NaviItem to={"/satunnaisia"} label={"Kansanviisauksia"}/>
                        <img src={gothicDot} alt="separator dot" className="w-1.5"/>
                        <NaviItem to={"/suosituimmat"} label={"Suositut"}/>
                        <img src={gothicDot} alt="separator dot" className="w-1.5"/>
                        <NaviItem to={"/etsi"} label={"Etsi"}/>
                    </div>
                    <NavLink to="/google-login">
                        {loginStatus.initials != null ?
                            <LoginButton initials={loginStatus.initials} email={loginStatus.email}/>
                            : 
                            <div className="scale-125">
                                <AccountCircleIcon />    
                            </div>
                        }
                    </NavLink>
                </div>
            </div>

            <div><Outlet/></div>
            <ScrollRestoration />
        </>
    );
}

function NaviItem(args: {to: string, label: string}) {
    const regular = "text-orange-800 dark:text-orange-300 border-b border-b-transparent";
    const active = regular + " border-b-black dark:border-b-white";
    return (
        <NavLink to={args.to} 
                 className={({ isActive }) => isActive ? active : regular}>
            {args.label}
        </NavLink>
    )
}